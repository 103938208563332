import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Phantom | François Deguire' />
    <ProjectPage
      name='Phantom'
      description={`
        Phantom is a high-performance wireless gaming mouse made in an advanced
        3D modeling course. A brochure was created for this fictional product.
        The goal of this project was to demonstrate the mastery of complex surface
        modeling and rendering software.
      `}
      meta={`
        For Université de Montréal<br>
        In 2017
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    phantom1: file(relativePath: { eq: "phantom/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phantom2: file(relativePath: { eq: "phantom/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phantom3: file(relativePath: { eq: "phantom/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phantom4: file(relativePath: { eq: "phantom/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phantom5: file(relativePath: { eq: "phantom/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
